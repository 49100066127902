import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import RequestForm from './Forms/RequestForm'
import styled from 'styled-components'


const Modal = styled.div`
  display: flex;
  color: white;
  font-weight: bold;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 20;
  
  &.exitintent-enter {
    opacity: 0.01;
  }
  
  &.exitintent-enter.exitintent-enter-active {
    opacity: 0.8;
    transition: opacity 500ms ease-in;
  }
  
  &.exitintent-exit {
    opacity: 0.8;
  }
  
  &.exitintent-exit.exitintent-exit-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
  }
`

export const ModalBackground = styled.div`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0.97;
  background-color: #69d49f;
  transition: 0.3s;
`

export const ModalContent = styled.div`
  margin: 0 20px;
  max-height: calc(100vh - 240px);
  overflow: auto;
  position: relative;
  padding: 20px;
`

const ExitModalHeader = styled.h1`
  font-weight: 200;
  font-size: 64px;
  text-align: center;
`

const ExitModalBody = styled.h3`
  font-weight: 200;
  text-align: center;
`

class ExitIntent extends Component {
  constructor(props) {
    super(props)
    this.state = { showExit: false }
    // This binding is necessary to make `this` work in the callback
    this.handleExit = this.handleExit.bind(this)
    this.handleExitClick = this.handleExitClick.bind(this)
  }

  componentDidMount() {
    document.addEventListener("mouseout", this.handleExit)
  }

  handleExitClick(e) {
    this.setState({
      showExit: false,
    })
  }

  handleExit(e) {
    e = e ? e : window.event
    // Get the current viewport width.
    const vpWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )

    // If the current mouse X position is within 50px of the right edge
    // of the viewport, return.
    if (e.clientX >= vpWidth - 50) return

    // If the current mouse Y position is not within 50px of the top
    // edge of the viewport, return.
    if (e.clientY >= 50) return

    // Reliable, works on mouse exiting window and
    // user switching active program
    const from = e.relatedTarget || e.toElement
    if (!from && !localStorage.getItem('exitintent_show')) {
      this.setState({
        showExit: true,
      }, () => {
        localStorage.setItem('exitintent_show', 'true')
      })
    }
  }

  render() {
    const { showExit } = this.state

    return (
      <CSSTransition
        in={showExit}
        classNames="exitintent"
        timeout={{ enter: 500, exit: 500 }}
      >
        { showExit ? (
          <Modal>
            <>
              <ModalBackground onClick={this.handleExitClick} />
              <ModalContent>
                <ExitModalHeader>Before you leave</ExitModalHeader>
                <ExitModalBody>
                  <p>
                    Sign up to stay in touch with SalaryAlly.
                  </p>
                  <p>
                    Get compensation research in your inbox!
                  </p>
                </ExitModalBody>
                <RequestForm emailonly={true} formKey={'newsletter'} source={'exit-intent'} />
              </ModalContent>
            </>
          </Modal>
        ) : <></>}
      </CSSTransition>
    )
  }
}
export default ExitIntent