const trigger = (action, data) => {
  // process.env.GATSBY_ACTIVE_ENV === 'production' && typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag('event', action, { ...data })
}

export const Login = (override = {}) => {
  trigger('login', {
    method: '',
    ...override
  })
}

export const NewsletterSignup = (override = {}) => {
  trigger('sign_up', {
    event_category: 'Newsletter',
    event_label: '',
    event_value: 0,
    ...override
  })
}

export const InsightsBeginCheckout = (override = {}) => {
  trigger('begin_checkout', {
    event_category: 'Insights',
    event_label: '',
    event_value: 0,
    ...override
  })
}

export const InsightsPurchase = (override = {}) => {
  trigger('purchase', {
    event_category: 'Insights',
    event_label: '',
    event_value: 0,
    ...override
  })
}

export const MonitoringBeginCheckout = (override = {}) => {
  trigger('begin_checkout', {
    event_category: 'Monitoring',
    event_label: '',
    event_value: 0,
    ...override
  })
}

export const MonitoringPurchase = (override = {}) => {
  trigger('purchase', {
    event_category: 'Monitoring',
    event_label: '',
    event_value: 0,
    ...override
  })
}