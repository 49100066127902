import React from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form'
import { SubmitButton } from '../UI/Buttons'
import * as events from '../../utils/events'

let request = typeof XMLHttpRequest !== 'undefined' && require('browser-request')

const onSubmit = async (values, formKey, source, onSuccess) => {
  const formSource = source || 'unknown'
  request({
    method: 'POST',
    uri: process.env.GATSBY_SHORT_FORM_URL,
    body: { ...values, formKey, formSource },
    json: true
  }, (err, resp, body) => {
    if (typeof onSuccess !== 'undefined') {
      onSuccess()
    } else {
      window.alert(`Thanks! We'll be in touch shortly.`)
    }
    events.NewsletterSignup({ event_label: source })
  })
}

const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validate = (values, emailonly ) => {
  const errors = {}
  if (!validateEmail(values.email)) {
    errors.email = "Valid email required"
  }
  if (emailonly) return errors

  if (!values.name) {
    errors.name = "Required"
  }

  return errors
}

const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 20px 0;
  font-size: 14px;
  
  @media only screen and (max-width : 480px) {
    flex-direction: column;
    align-items: center;
  }  
`

const FieldWrapper = styled.div`
  flex: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  
  input {
    color: #333;
    padding: 2px 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    
    ::placeholder {
      font-size: 12px;
    }
  }
  
  @media only screen and (max-width : 480px) {
    margin-right: 0;
    
    input {
      padding: 2px 11px;
    }
    
  }  
`

const ErrorWrapper = styled.div`
  color: #db8f8c;
  font-size: 11px;
  padding: 0 0 0 5px;
`


const RequestForm = ({ formKey, emailonly, source, onSuccess }) => (
  <Form
    onSubmit={(values) => { onSubmit(values, formKey, source, onSuccess) } }
    validate={(values) => validate(values, emailonly)}
    render={({ handleSubmit, pristine, invalid }) => (
      <form onSubmit={handleSubmit}>
        <FormWrapper>
          { !emailonly ? (
            <FieldWrapper>
              <Field name="name">
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="text" placeholder="Name" />
                    <ErrorWrapper>
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </ErrorWrapper>
                  </div>
                )}
              </Field>
            </FieldWrapper>
          ) : (
            <FieldWrapper>
              <Field name="name">
                {({ input, meta }) => (
                  <div>
                    <input {...input} type="hidden" value="Email Only" />
                  </div>
                )}
              </Field>
            </FieldWrapper>
          ) }
          <FieldWrapper>
            <Field name="email">
              {({ input, meta }) => (
                <div>
                  <input {...input} type="text" placeholder={emailonly ? 'Email' : 'Email'} />
                  <ErrorWrapper>
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </ErrorWrapper>
                </div>
              )}
            </Field>
          </FieldWrapper>
          <SubmitButton type="submit" disabled={pristine || invalid}>
            Submit
          </SubmitButton>
        </FormWrapper>
      </form>
    )}
  />
)

export default RequestForm