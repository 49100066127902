import React from 'react'

const AtWorkImage = (
  style = {},
  fill = '',
  width = '',
  className = '',
  viewBox = '0 0 853.53 770') =>
  <svg id="ac370e99-250f-4203-ae2c-c306a69114f3" className={`svg-icon ${className || ""}`}
       width={"100%"}
       style={style}
       height={"100%"}
       viewBox={viewBox}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
              <linearGradient id="7db19e5e-7cc3-41df-8a16-c81cf46c1195" x1="758.38" y1="801.59" x2="758.38" y2="131.56"
                              gradientTransform="translate(-136.27 -110.44) rotate(4.65)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="gray" stopOpacity="0.25"/>
                    <stop offset="0.54" stopColor="gray" stopOpacity="0.12"/>
                    <stop offset="1" stopColor="gray" stopOpacity="0.1"/>
              </linearGradient>
              <linearGradient id="87908c47-1c47-4a2f-b745-20cb7009bb75" x1="685.97" y1="797.2" x2="685.97" y2="126.94"
                              gradientTransform="translate(-242.89 51.18) rotate(-10.87)"
                              xlinkHref="#7db19e5e-7cc3-41df-8a16-c81cf46c1195"/>
              <linearGradient id="f482b758-09b8-4320-b57c-ecd1bdf3658e" x1="517.93" y1="719.46" x2="517.93" y2="49.68"
                              gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                              xlinkHref="#7db19e5e-7cc3-41df-8a16-c81cf46c1195"/>
              <linearGradient id="720977dd-28dd-4cd1-b9d4-02f8d793e7ce" x1="553.48" y1="380.79" x2="559.93" y2="535.01"
                              gradientTransform="matrix(-1, 0.01, -0.01, -1, 1253.94, 688.13)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopOpacity="0.12"/>
                    <stop offset="0.55" stopOpacity="0.09"/>
                    <stop offset="1" stopOpacity="0.02"/>
              </linearGradient>
              <linearGradient id="e8979000-d63e-4fe0-8af1-0dfdd65d3420" x1="356.17" y1="639.4" x2="356.17" y2="574"
                              gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                              xlinkHref="#7db19e5e-7cc3-41df-8a16-c81cf46c1195"/>
              <linearGradient id="3889af47-bc7c-4009-b8ee-af9d60c73d61" x1="296" y1="675.66" x2="296" y2="478.3"
                              gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
                              xlinkHref="#7db19e5e-7cc3-41df-8a16-c81cf46c1195"/>
        </defs>
        <title>files</title>
        <polygon points="799.57 770 309.95 729.85 363.91 62.28 853.53 102.43 799.57 770"
                 fill="url(#7db19e5e-7cc3-41df-8a16-c81cf46c1195)"/>
        <polygon points="793.7 760.47 317.27 721.39 369.78 71.81 846.21 110.88 793.7 760.47" fill="#69d49f"/>
        <polygon points="821.86 657.75 339.44 751.09 214 93.34 696.42 0 821.86 657.75"
                 fill="url(#87908c47-1c47-4a2f-b745-20cb7009bb75)"/>
        <polygon points="813.67 650.15 344.25 740.98 222.19 100.94 691.61 10.12 813.67 650.15" fill="#e0e0e0"/>
        <rect x="272.31" y="49.68" width="491.24" height="669.78" fill="url(#f482b758-09b8-4320-b57c-ecd1bdf3658e)"/>
        <rect x="278.93" y="58.7" width="478.01" height="651.73" fill="#fff"/>
        <path d="M613.25,236.15c.52,43.61,36.05,78.54,79.35,78s78-36.31,77.46-79.93a79,79,0,0,0-52.75-73.69l.24,20.34A26.73,26.73,0,0,1,691.32,208h0a26.72,26.72,0,0,1-26.87-26.42l-.24-20.34A79,79,0,0,0,613.25,236.15Z"
              transform="translate(-173.24 -65)" fill="url(#720977dd-28dd-4cd1-b9d4-02f8d793e7ce)"/>
        <path d="M770.09,227.84c0-43.62-35.1-79-78.41-79s-78.41,35.36-78.41,79a79,79,0,0,0,51.86,74.33V281.83a26.72,26.72,0,0,1,26.55-26.74h0a26.72,26.72,0,0,1,26.55,26.74v20.35A79,79,0,0,0,770.09,227.84Z"
              transform="translate(-173.24 -65)" fill="#69d49f"/>
        <ellipse cx="518.49" cy="151.98" rx="30.14" ry="30.36" fill="#fff" stroke="#fff" strokeMiterlimit="10"/>
        <path d="M691.68,255.09h0a26.72,26.72,0,0,0-26.55,26.74v20.35a78.17,78.17,0,0,0,53.1,0V281.83A26.72,26.72,0,0,0,691.68,255.09Z"
              transform="translate(-173.24 -65)" fill="#fff" stroke="#fff" strokeMiterlimit="10"/>
        <path d="M661.33,215.3A29.63,29.63,0,0,1,658.12,200a20.48,20.48,0,0,1,6.68-13.85c4.39-3.77,10.34-5.1,16.09-5.56s11.58-.18,17.24-1.31a36,36,0,0,0,23-15.72c.36,4.45.72,9-.13,13.36s-3.09,8.7-6.87,11c4.58-2.13,4.08,2.87,3.09,5.86,2.27,2.76,7.78,3.22,8.06,6.79s.23,7.26-1.15,10.55.1,9.1-3.44,9.35"
              transform="translate(-173.24 -65)" fill="#fff" stroke="#fff" strokeMiterlimit="10"/>
        <path d="M715.81,187.26s21,1.85,6.06,33.29" transform="translate(-173.24 -65)" fill="#fff"/>
        <rect x="323.71" y="574" width="64.93" height="65.4" fill="url(#e8979000-d63e-4fe0-8af1-0dfdd65d3420)"/>
        <rect x="329.3" y="579.64" width="53.73" height="54.12" fill="#69d49f"/>
        <rect x="440.13" y="303.38" width="156.72" height="11.28" fill="#69f0ae"/>
        <rect x="440.13" y="319.17" width="264.19" height="11.28" fill="#e0e0e0"/>
        <rect x="440.13" y="334.95" width="264.19" height="11.28" fill="#e0e0e0"/>
        <rect x="440.13" y="444.33" width="156.72" height="11.28" fill="#69f0ae"/>
        <rect x="440.13" y="460.11" width="264.19" height="11.28" fill="#e0e0e0"/>
        <rect x="440.13" y="475.9" width="264.19" height="11.28" fill="#e0e0e0"/>
        <rect x="440.13" y="585.27" width="156.72" height="11.28" fill="#69d49f"/>
        <rect x="440.13" y="601.06" width="264.19" height="11.28" fill="#e0e0e0"/>
        <rect x="440.13" y="616.85" width="264.19" height="11.28" fill="#e0e0e0"/>
        <path d="M405.46,569.67H368.88c-3.72-51.08-46-91.37-97.67-91.37-54.11,0-98,44.18-98,98.68s43.86,98.68,98,98.68c47.48,0,87.06-34,96-79.19h38.21a13.4,13.4,0,0,0,0-26.8Z"
              transform="translate(-173.24 -65)" fill="url(#3889af47-bc7c-4009-b8ee-af9d60c73d61)"/>
        <path d="M273.05,485.65c-50.08,0-90.68,40.89-90.68,91.33s40.6,91.33,90.68,91.33,90.68-40.89,90.68-91.33S323.13,485.65,273.05,485.65Zm0,169.14c-42.66,0-77.24-34.83-77.24-77.8s34.58-77.8,77.24-77.8S350.29,534,350.29,577,315.71,654.78,273.05,654.78Z"
              transform="translate(-173.24 -65)" fill="#fff"/>
        <path d="M182.66,505.22h42.73a11,11,0,0,1,11,11V519a11,11,0,0,1-11,11H182.66a0,0,0,0,1,0,0V505.22A0,0,0,0,1,182.66,505.22Z"
              fill="#fff"/>
        <ellipse cx="99.82" cy="511.98" rx="78.36" ry="78.93" fill="#69d49f"/>
        <path d="M281.81,547.55c-6.11,3.2-9.06,12-5,17.62,1.62,2.23,4,3.71,6.07,5.55s3.82,4.41,3.46,7.15a10,10,0,0,1-2.25,4.51c-4.11,5.44-9.18,10.61-10.51,17.31-1.2,6.08,1,12.44,4.58,17.49,2,2.87,4.69,5.53,8.08,6.45,3.75,1,7.81-.32,11-2.61s5.54-5.45,7.88-8.56l5.45-7.24c1.49-2,3-4.13,3.16-6.61.19-4.26-3.8-7.45-5.16-11.48-2.52-7.48,4.43-15.21,3.5-23.06C310.64,552.21,292.34,542,281.81,547.55Z"
              transform="translate(-173.24 -65)" opacity="0.2"/>
  </svg>
export default AtWorkImage