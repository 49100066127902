import styled from "styled-components";


export const SubmitButton = styled.button`
  cursor: pointer;
  background: #4B4B7D;
  border: 2px solid #4B4B7D;
  border-radius: 4px;
  width: 170px;
  color: white;
  text-decoration: none; 
  padding: 0 5px; 
  text-align: center;
  font-weight: 400;
  max-height: 31px;
  
  &:hover {
    background: white;
    color: #4B4B7D;
    border-color: #4B4B7D;
  }  
`

export const DarkPrimaryButton = styled.div`
  flex: 0;
  background: #db8f8c;
  border: 2px solid #db8f8c;
  border-radius: 4px;
  margin: 40px auto;
  color: #F3A09C;
  width: 174px;
  
  a {
    cursor: pointer;
    display: block;
    width: 170px;
    color: white;
    text-decoration: none; 
    padding: 5px; 
    text-align: center;
    font-weight: 400;
  }
  
  &:hover, &:hover > a {
    background: white;
    color: #db8f8c;
    border-color: white;
  } 
  
  @media only screen and (max-width : 480px) {
    margin: 0;
  }  
`

export const DarkSecondaryButton = styled.div`
  background: transparent;
  border: 2px solid #375FA6;
  border-radius: 4px;
  margin: 40px auto;
  color: #375FA6;
  width: 134px;
  
  a, span {
    cursor: pointer;
    display: block;
    width: 130px;
    color: #375FA6;
    text-decoration: none; 
    padding: 5px; 
    text-align: center;
    font-weight: 400;
  }
  
  &:hover, &:hover > a, &:hover > span {
    background: #375FA6;
    color: white;
  } 
`

export const WhiteSecondaryButton = styled.div`
  background: transparent;
  border: 2px solid white;
  border-radius: 4px;
  margin: 40px auto;
  color: white;
  width: 134px;
  
  a, span {
    cursor: pointer;
    display: block;
    width: 130px;
    color: white;
    text-decoration: none; 
    padding: 5px; 
    text-align: center;
    font-weight: 400;
  }
  
  &:hover, &:hover > a, &:hover > span {
    background: white;
    color: #69d49f;
  } 
`

export const LightSecondaryButton = styled(DarkSecondaryButton)`
    flex: 0;
    border: 2px solid #4B4B7D;
    color: #4B4B7D;
    
    a {
      color: #4B4B7D;
    }
    
    &:hover, &:hover > a {
      background: #4B4B7D;
      color: white;
    } 

`