import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { LogoIcon } from '../svgs'

const HeaderMain = styled.div`
  display: flex;
  position: relative;
  height: 60px;
  max-width: 960px;
  margin: 0 auto;
  background: ${props => props.theme && props.theme.background};
`

const LogoWrapper = styled.div`
  flex: 1 0 auto;
  padding: 20px 1.0875rem;
`

const Logo = styled.h4`
  margin: 0; 
  font-weight: 300;
  
  a {
    display: inline-block;
    vertical-align: middle;
  }
`

const LogoIconWrapper = styled.div`
  display: none;
  margin-right: 8px;
  margin-top: 3px;
  vertical-align: middle;
`

const StickyHeader = ({ siteTitle, transparent }) => (
  <HeaderMain theme={{ background: transparent ? 'none' : '' }}>
    <LogoWrapper>
      <Logo>
        <LogoIconWrapper>
          <LogoIcon width='32px' />
        </LogoIconWrapper>
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
            fontSize: '28px',
          }}
        >
          <strong>Salary<i>Ally</i></strong>
        </Link>
      </Logo>
    </LogoWrapper>
  </HeaderMain>
)

StickyHeader.propTypes = {
  siteTitle: PropTypes.string,
}

StickyHeader.defaultProps = {
  siteTitle: '',
}

export default StickyHeader
