import React from 'react'

const LogoIcon = (
  style = {},
  fill = '',
  width = '',
  className = '',
  viewBox = '0 0 589 368') =>
  <svg id="ac370e99-250f-4203-ae2c-c306a69114f3" className={`svg-icon ${className || ""}`}
       width={"100%"}
       style={style}
       height={"100%"}
       viewBox={viewBox}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-11-Copy" transform="translate(0.000000, -111.000000)">
        <rect id="Rectangle-7-Copy-6" strokeOpacity="0" stroke="#FFFFFF" fillOpacity="0" fill="#A78080" x="0.5" y="0.5" width="588" height="588"></rect>
        <g id="Bowtie-Copy" transform="translate(0.000000, 111.000000)">
          <path d="M292.647799,282.212157 C274.661961,278.407873 256.382311,282.643066 240.710808,292.970499 C188.477079,343.606336 -1.58420166e-13,445.030529 0,195.860375 C1.98025207e-13,-53.3097783 171.20806,-15.7686647 240.710808,44.5847215 C260.601427,61.8569343 280.265822,76.3855072 298.204403,85.4282541 L298.204403,85.7951789 C316.067397,89.5866722 334.220149,85.3502866 349.784291,75.0295011 C401.693586,24.3936637 589,-77.0305287 589,172.139625 C589,421.309778 418.855344,383.768665 349.784291,323.415278 C330.017215,306.143066 310.474959,291.614493 292.647799,282.571746 L292.647799,282.212157 Z M294.15,135.957611 C283.931425,131.541882 272.934752,125.12462 261.825454,117.648849 C218.27617,88.343264 111,70.1145554 111,191.103245 C111,312.091934 229.096654,262.84374 261.825454,238.256672 C272.658475,232.724432 285.480477,230.792548 297.85,233.730563 L297.85,233.042389 C308.068575,237.458118 319.065248,243.87538 330.174546,251.351151 C373.72383,280.656736 481,298.885445 481,177.896755 C481,56.9080658 362.903346,106.15626 330.174546,130.743328 C319.341525,136.275568 306.519523,138.207452 294.15,135.269437 L294.15,135.957611 Z" id="Combined-Shape" fill="#FFFFFF"></path>
          <g id="bowtie--inner" transform="translate(60.000000, 127.000000)"></g>
        </g>
      </g>
    </g>
  </svg>
export default LogoIcon