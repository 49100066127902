import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 40px;
  max-width: 960px;
  height: 100%;
`

const StyledFooter = styled.div`
  position: relative;
  min-height: 120px;
  background: #444;
  color: #ddd;
  padding: 40px;
  
  @media only screen and (max-width : 480px) {
    text-align: center;
  }  
`

const FooterGroups = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  
  @media only screen and (max-width : 480px) {
    align-items: center;
    justify-content: center;
  } 
`

const FooterGroup = styled.div`
  display: block;
  min-width: 250px;
  margin-bottom: 30px;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`

const GroupTitle = styled.h5`
  margin-bottom: 4px;
  text-transform: uppercase;
`

const FooterLink = styled(Link)`
  display: block;
  margin: 0;
  font-size: 12px;
  color: #ddd;
  text-decoration: none;
  
  &:hover {
    color: white;
  }
  
  @media only screen and (max-width : 480px) {
    display: block;
    text-align: center;
  }  
`

const ExternalLink = styled.a`
  display: block;
  margin: 0;
  font-size: 12px;
  color: #ddd;
  text-decoration: none;
  
  &:hover {
    color: white;
  }
  
  @media only screen and (max-width : 480px) {
    display: block;
    text-align: center;
  }  
`

const MadeIn = styled.div`
  text-align: right;
  font-size: 11px;
  padding-top: 40px;
  
  @media only screen and (max-width : 480px) {
    position: relative;
    bottom: 0;
    right: 0;
    margin-top: 20px;
  }  
`

const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterGroups>
        <FooterGroup style={{minWidth: '150px'}}>
          <GroupTitle>Services</GroupTitle>
          <FooterLink to={'/#pricing'}>
            Get Started
          </FooterLink>
        </FooterGroup>
        <FooterGroup style={{minWidth: '150px'}}>
          <GroupTitle>About</GroupTitle>
          <FooterLink to={'terms'}>
            Terms of Service
          </FooterLink>
          <FooterLink to={'privacy-policy'}>
            Privacy Policy
          </FooterLink>
          <ExternalLink href={'mailto:hello@salaryally.com'}>
            Contact Us
          </ExternalLink>
        </FooterGroup>
        <FooterGroup>
          <GroupTitle>Recent Posts</GroupTitle>
          <FooterLink to={'/blog/why-salaryally'}>
            Why SalaryAlly
          </FooterLink>
        </FooterGroup>
      </FooterGroups>
      <MadeIn>
        <FooterLink to={'/blog/why-salaryally'}>
        Made with <span style={{fontSize: '14px', verticalAlign: 'bottom'}} role='img' aria-label='economic empowerment'>💪💸</span>&#8239;in San Francisco
        </FooterLink>
      </MadeIn>
    </Container>
  </StyledFooter>
)


export default Footer