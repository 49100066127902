import React from 'react'

const InterviewImage = (
  style = {},
  fill = '',
  width = '',
  className = '',
  viewBox = '0 0 1084.15 758.57') =>
  <svg id="ac370e99-250f-4203-ae2c-c306a69114f3" className={`svg-icon ${className || ""}`}
       width={"100%"}
       style={style}
       height={"100%"}
       viewBox={viewBox}
       xmlns="http://www.w3.org/2000/svg"
       xmlnsXlink="http://www.w3.org/1999/xlink">
    <path d="M519.15,99.87c-44.07-16.76-90.64-30.76-138.18-29-69.9,2.58-137,41.91-169.86,99.66-27.88,48.93-31.32,106.28-43.33,160.58C153,398,124.64,461.59,94.36,523.76c-16.26,33.4-33.42,67.52-36.09,104.13-3.7,50.83,22.27,101.48,63,135.29s94.82,51.65,149.11,55.54c28.89,2.07,59.08.15,84.74-12.43,27.15-13.31,46.94-37.36,74.2-50.48,25.26-12.16,54.81-13.86,83.17-12.28a394.64,394.64,0,0,1,108.58,21.6c39.64,13.92,76.5,34.09,116,48.21s83.74,22,124.16,10.22c38-11.07,68-37.95,95.05-65.17A1155.18,1155.18,0,0,0,1077.5,615c24.41-34.21,47.13-70.3,57.92-110.17,20.64-76.23-7.76-162.23-70.75-214.29-52.19-43.13-122.23-61.85-190.79-73.21-62.52-10.36-134.81-11-192.36-38C626.64,153.55,576.7,121.77,519.15,99.87Z"
          transform="translate(-57.92 -70.71)" fill="#FFFFFF" opacity="1"/>
    <polygon points="632.58 619 632.58 655.33 642.91 655.33 640.91 616.33 632.58 619" fill="#aaabb0"/>
    <polygon points="477.41 611.67 477.41 648 487.75 648 485.75 609 477.41 611.67" fill="#aaabb0"/>
    <rect x="644.58" y="551.67" width="13.33" height="64.67" rx="6.67" ry="6.67" fill="#aaabb0"/>
    <rect x="473.58" y="537.33" width="13.33" height="79" rx="6.67" ry="6.67" fill="#aaabb0"/>
    <path d="M732.84,235.38s13.33-5.67,15.33,0,3,54.67,3,54.67H738.5Z" transform="translate(-57.92 -70.71)"
          fill="#aaabb0"/>
    <path d="M586.84,235.38s-13.34-5.67-15.34,0-3,54.67-3,54.67h12.67Z" transform="translate(-57.92 -70.71)"
          fill="#aaabb0"/>
    <polygon points="530.91 116 528.91 156.67 537.91 156.67 537.91 115 530.91 116" fill="#aaabb0"/>
    <path d="M581.17,290.05l2.67-58.67s-1-13,30.66-9,124.34,12,124.34,12v55.67Z" transform="translate(-57.92 -70.71)"
          fill="#33323d"/>
    <path d="M707.84,599.05l-5,89.66s-73.34,40.67-162.34,2l8-94.33Z" transform="translate(-57.92 -70.71)"
          fill="#33323d"/>
    <polygon points="580.25 175.33 581.91 233.33 685.25 233.33 679.25 156.67 580.25 175.33" fill="#444053"/>
    <rect x="493.41" y="490" width="97.34" height="123.33" fill="#4c4981"/>
    <path d="M612.84,611.05s14-11,16.33-7.67-8.32,17.67-12.66,16S612.84,611.05,612.84,611.05Z"
          transform="translate(-57.92 -70.71)" fill="#ffb9b9"/>
    <rect x="304.51" y="216" width="638.97" height="320" fill="#504f60"/>
    <path d="M581.5,184.38s-5-46.67,0-52.33,89-59.34,166.67,3.66c0,0,7.33.34,6,17a200,200,0,0,0,.16,31.67S587.17,196.38,581.5,184.38Z"
          transform="translate(-57.92 -70.71)" fill="#33323d"/>
    <path d="M581.5,184.38s-5-46.67,0-52.33,89-59.34,166.67,3.66c0,0,7.33.34,6,17a200,200,0,0,0,.16,31.67S587.17,196.38,581.5,184.38Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M581.5,190.38s-5-46.67,0-52.33,89-59.34,166.67,3.66c0,0,7.33.34,6,17a200,200,0,0,0,.16,31.67S587.17,202.38,581.5,190.38Z"
          transform="translate(-57.92 -70.71)" fill="#33323d"/>
    <path d="M757.17,169.05s5.33,20,3.67,23.33-1.36,12.67-1.36,12.67.36,6-2.31,6.66-5,1-2.84,3,0,5,0,5-1.17,4.67,0,7.67-6.84,4.25-6.16,8,2.92,14.33,0,14.67,1,13.33-1.63,13.33-4.5,23.33-4.5,23.33-18.5-15.66-30.83-11.33c0,0,1-13,5-17.67S719,241.05,719,241.05l19.84-59.67Z"
          transform="translate(-57.92 -70.71)" fill="#4c4981"/>
    <path d="M658.17,444.83c-4.33,4.79-9-20.79-9-20.79s-12.33,17-20.33,5.67c-5.54-7.85-1.17-30.09,2-43,1.42-5.74,2.62-9.64,2.62-9.64l18-3s.06,3.92.46,8.68c.54,6.43,1.71,14.41,4.2,16.32C660.51,402.38,662.51,440,658.17,444.83Z"
          transform="translate(-57.92 -70.71)" fill="#ae6e79"/>
    <path d="M648.84,145.38s-31.34-.33-39,16.33-8,22.34-11,25,32.66,16.67,32.66,16.67l1.67,57.33s7-7.09,18.33-3.88,25.67,0,25.67,0-.67-1.21,8.67,0,39-14.45,39-14.45.49-25.08-.17-30.42,21.16-44.58,21.16-44.58,17-16.33-.66-22c0,0-6.25-9.42-22.33-9.33S648.84,145.38,648.84,145.38Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M648.84,144.38s-31.34-.33-39,16.33-8,22.34-11,25,32.66,16.67,32.66,16.67l1.67,57.33s7-7.09,18.33-3.88,25.67,0,25.67,0-.67-1.21,8.67,0,39-14.45,39-14.45,11.66-19,11-24.33,23.33-45.67,23.33-45.67,3.67-21.33-14-27c0,0-6.25-9.42-22.33-9.33S648.84,144.38,648.84,144.38Z"
          transform="translate(-57.92 -70.71)" fill="#4c4981"/>
    <path d="M652,382.72c-6.87-2.14-17.65,2.58-21.08,4,1.42-5.74,2.62-9.64,2.62-9.64l18-3S651.57,378,652,382.72Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M608.5,185.71h-9.66s-2,20.34,0,26.34,3,5.13,2.33,9.57,4.67,11.24,4.67,11.24.66,16.76,4,23-3.67,34.22-3.67,34.22a40.26,40.26,0,0,0,8.33,13c5.67,5.66,8.67,26.66,7,34.66s4.67,11,4.67,11,2.67,37.34,3.67,37.34,20.33-10,26.33-1.34l-4.67-34.33.34-66.33s-6.34-8.34-3-15.67-2.67-24.67-2.67-24.67l-8.67-38.66Z"
          transform="translate(-57.92 -70.71)" fill="#4c4981"/>
    <path d="M548.5,712.71s-14-3-14.33,0-16.67,33.67,3.67,42.67,74.66,28.33,163,3.33c0,0,5.66,2,4.33-10.33s-1.33-26-1.33-26-13.34-3.67-23.34-2.33S583.5,739.05,548.5,712.71Z"
          transform="translate(-57.92 -70.71)" fill="#33323d"/>
    <path d="M548.5,712.71s-14-3-14.33,0-16.67,33.67,3.67,42.67,74.66,28.33,163,3.33c0,0,5.66,2,4.33-10.33s-1.33-26-1.33-26-13.34-3.67-23.34-2.33S583.5,739.05,548.5,712.71Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M548.5,708.71s-14-3-14.33,0-16.67,33.67,3.67,42.67,74.66,28.33,163,3.33c0,0,5.66,2,4.33-10.33s-1.33-26-1.33-26-13.34-3.67-23.34-2.33S583.5,735.05,548.5,708.71Z"
          transform="translate(-57.92 -70.71)" fill="#33323d"/>
    <path d="M630.14,197.69c-.86,4.89-2.81,9.59-3.11,14.54s1.61,10.58,6.18,12.52a20.83,20.83,0,0,1,3.69,1.5c1.76,1.19,2.48,3.43,2.85,5.53a30.2,30.2,0,0,1,.35,8.22c-.25,2.76-.84,5.73.52,8.14,2.39,4.27,8.62,3.64,13.36,2.44l-1.63,12.63c-.13,1-.21,2.13.52,2.81a3.15,3.15,0,0,0,2.4.52c4.12-.26,8.36-.83,11.89-3s6.16-6.21,5.49-10.28c-.78-4.72-5.66-8.47-5-13.21.51-3.67,4.1-6,6.46-8.82,4.27-5.16,4.5-13,1.15-18.84s-9.82-9.46-16.48-10.12c-3.69-.37-7.53.08-11-1.2a8.34,8.34,0,0,1-5.1-5.38"
          transform="translate(-57.92 -70.71)" fill="#444053"/>
    <path d="M513.84,642.38l3.49-7.6S536.17,617.23,540.5,616s22-6.59,24.67-6.59,51.34-2.67,51.34-2.67l5.66,13.34-61.08,13.41-20.59,15Z"
          transform="translate(-57.92 -70.71)" fill="#767d9f"/>
    <path d="M660.59,437.71l-3.19,24.55-1.23,9.45-14.33,2s-1.55-3.32-3.74-8.42c-6.65-15.48-19.17-47.4-11.91-52.91,9.65-7.34,12,17,12,17l11-5.34Z"
          transform="translate(-57.92 -70.71)" fill="#ffb9b9"/>
    <rect x="527.91" y="412.67" width="72.37" height="109.33" fill="#fff"/>
    <rect x="527.91" y="412.67" width="72.37" height="109.33" opacity="0.1"/>
    <rect x="610.8" y="483.38" width="72.37" height="109.33" transform="translate(27.68 -159.51) rotate(8.41)"
          fill="#fff"/>
    <rect x="621.61" y="491.41" width="37.25" height="56.27" transform="translate(24.9 -158.72) rotate(8.41)"
          fill="#69d49f"/>
    <rect x="612.61" y="563.73" width="45.13" height="12.22" transform="translate(32.2 -157.44) rotate(8.41)"
          fill="#69d49f"/>
    <circle cx="619.25" cy="95" r="41.83" opacity="0.1"/>
    <circle cx="619.25" cy="94" r="41.83" fill="#ae6e79"/>
    <path d="M642.68,195.71a4.76,4.76,0,0,1,.29-3.23c1.59-3.31,6.12-3.6,9.79-3.43,8.63.4,18.1.45,24.85-5,2.8-2.24,5.93-7.87,10-6.8,3.62.95,5.13,5.86,8.9,6.95,2.73.79,5.56-.47,8.34-1,4-.79,8.1-.13,12.16-.1s8.47-.74,11.24-3.71c3.74-4,3.13-10.25,2.8-15.73a84.69,84.69,0,0,1,1.43-21.14c1.36-6.92,3.45-14.66-.37-20.59-3.64-5.65-11.18-7.2-16.33-11.53-4.82-4-7.37-10.41-12.46-14.12-6.41-4.67-15.12-4-23-3.19l-17.1,1.82c-4.41.47-9,1-12.63,3.63-6.55,4.74-7.45,14.59-13.65,19.78-2.76,2.3-6.29,3.44-9.44,5.16s-6.19,4.44-6.55,8c-.3,3,1.32,5.77,2.11,8.66,1.87,6.9-1.05,14-2.92,20.95s-2.23,15.39,3.2,20c1.1.94,2.38,1.66,3.45,2.64,3.67,3.35,4.17,9,3.32,13.88"
          transform="translate(-57.92 -70.71)" fill="#444053"/>
    <ellipse cx="622.66" cy="143.75" rx="1" ry="2.5" fill="#444053"/>
    <ellipse cx="622.66" cy="154.17" rx="1" ry="2.5" fill="#444053"/>
    <path d="M658.4,444.76c1.07-1.23,1.78-4.39,2.19-8.56l-11.92-14.86S653.72,450.15,658.4,444.76Z"
          transform="translate(-57.92 -70.71)" fill="#ae6e79"/>
    <path d="M671.84,690.38s-19.56,30.24-80.67,27c-57.57-3-85.67-31.33-84.33-41.33s-3.67-34.34,8.66-41.34c7.15-4.05,16.56-1.65,23.2,1.09a13.1,13.1,0,0,0,13.77-2.47c6.07-5.47,17.39-11.63,35.26-6.78a12.7,12.7,0,0,0,7-.13c7.44-2.27,25.15-6.21,38,2.54a13,13,0,0,0,13.92.53l.18-.11c6-3.67,34,31,34,31S682.17,687.05,671.84,690.38Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M671.84,691.38s-19.56,30.24-80.67,27c-57.57-3-85.67-31.33-84.33-41.33s-3.67-34.34,8.66-41.34c7.15-4.05,16.56-1.65,23.2,1.09a13.1,13.1,0,0,0,13.77-2.47c6.07-5.47,17.39-11.63,35.26-6.78a12.7,12.7,0,0,0,7-.13c7.44-2.27,25.15-6.21,38,2.54a13,13,0,0,0,13.92.53l.18-.11c6-3.67,34,31,34,31S682.17,688.05,671.84,691.38Z"
          transform="translate(-57.92 -70.71)" fill="#767d9f"/>
    <circle cx="533.25" cy="592.67" r="39.84" opacity="0.1"/>
    <circle cx="533.25" cy="593.67" r="39.84" fill="#ffb9b9"/>
    <path d="M579.75,629.94c-4.18,1.13-8.3,3.29-10.34,6.84-1.23,2.14-1.66,4.71-3.44,6.49-2.52,2.53-6.77,2.58-10.18,4s-5.66,6.42-2.32,8c-3.48.18-6.06,3.39-6.49,6.58s.78,6.37,1.95,9.41c3.82,9.84,7.93,20.11,16.35,27.11,13.72,11.42,34.4,10.89,52.83,9.68a8.64,8.64,0,0,0,4.33-1c2-1.32,2.25-3.85,2.58-6.07,1.39-9.48,6.53-18.09,9.89-27.14s4.74-19.68-.92-27.69C625.66,634.27,593.77,626.14,579.75,629.94Z"
          transform="translate(-57.92 -70.71)" fill="#b96b6b"/>
    <path d="M657.4,462.26l-1.23,9.45-14.33,2s-1.55-3.32-3.74-8.42l19.07-3.58Z" transform="translate(-57.92 -70.71)"
          opacity="0.1"/>
    <path d="M672.5,671.05l8.34-9.67-1.67-53.33a86.51,86.51,0,0,0,1.67-11.67c.33-5.67-4.17-18.33-4.17-18.33a39.71,39.71,0,0,1,2.83-7.34c2-4-2.82-13-2.82-13s-7.18-23.33-4.18-35-.73-18.33-.73-18.33-2.93-13-2.6-18.33-6.67-10.34-6.67-10.34l-5.33-13-21.33,4v45s1.33,43,0,51.34,2.66,16.33,2.66,16.33-2.33,12.33-.33,17,6,16,6,16a26,26,0,0,0,.67,12.16c2,5.84,0,9.17,0,9.17Z"
          transform="translate(-57.92 -70.71)" fill="#767d9f"/>
    <g opacity="0.1">
      <path d="M732.51,139.23A84.66,84.66,0,0,0,731,157a85.07,85.07,0,0,1,1.55-14.76c.88-4.44,2-9.22,1.76-13.65A83,83,0,0,1,732.51,139.23Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M626.82,180.51c-1.07-1-2.35-1.7-3.45-2.65-2.92-2.49-4.15-6.1-4.39-10-.29,5,.73,9.84,4.39,13,1.1.95,2.38,1.67,3.45,2.65a12.22,12.22,0,0,1,3.62,8.35C630.73,187.66,629.88,183.29,626.82,180.51Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M623.63,143.17a18.85,18.85,0,0,0-.54-6.29c-.58-2.14-1.62-4.23-2-6.38,0,.23-.08.47-.11.72-.29,3,1.33,5.77,2.11,8.66A17,17,0,0,1,623.63,143.17Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M728.28,176.1c-2.77,3-7.18,3.74-11.24,3.71s-8.17-.69-12.16.11c-2.78.56-5.61,1.81-8.34,1-3.77-1.09-5.28-6-8.89-6.95-4.11-1.08-7.24,4.55-10,6.79-6.75,5.4-16.22,5.35-24.85,5-3.67-.17-8.2.12-9.78,3.44a4.64,4.64,0,0,0-.29,3.23l-12.22,1.92a23.71,23.71,0,0,1-.33,3l12.55-2a4.64,4.64,0,0,1,.29-3.23c1.58-3.32,6.11-3.61,9.78-3.44,8.63.4,18.1.45,24.85-5,2.8-2.24,5.93-7.87,10-6.79,3.61.94,5.12,5.86,8.89,6.95,2.73.78,5.56-.47,8.34-1,4-.8,8.1-.14,12.16-.11s8.47-.74,11.24-3.71,3.17-7.4,3-11.72C731.16,170.65,730.47,173.75,728.28,176.1Z"
            transform="translate(-57.92 -70.71)"/>
    </g>
    <g opacity="0.1">
      <path d="M635.71,224.13a20.23,20.23,0,0,0-3.69-1.5,9.55,9.55,0,0,1-4.83-4.94c.75,3.45,2.65,6.59,5.83,7.94a20.23,20.23,0,0,1,3.69,1.5,5.54,5.54,0,0,1,1.77,2.07C638.08,227.24,637.35,225.24,635.71,224.13Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M652.79,248.46c-4.61,1.17-10.63,1.78-13.16-2.13a6.87,6.87,0,0,0,.8,2.69c2.16,3.85,7.44,3.72,11.93,2.77Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M671.47,251.2c.64,4.06-2,8.11-5.5,10.24s-7.77,2.72-11.89,3a4.08,4.08,0,0,1-1.94-.22,2.38,2.38,0,0,0,2.94,3.22c4.12-.26,8.36-.83,11.89-3s6.17-6.21,5.49-10.28A11.34,11.34,0,0,0,671.47,251.2Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M675.91,215a16.46,16.46,0,0,1-3,14.15c-2.36,2.86-5.95,5.15-6.46,8.82a8.33,8.33,0,0,0,1,4.87,6.5,6.5,0,0,1,0-1.87c.51-3.67,4.1-6,6.46-8.82C677.79,227.46,678.33,220.55,675.91,215Z"
            transform="translate(-57.92 -70.71)"/>
    </g>
    <g opacity="0.1">
      <path d="M555.8,648.81c3.4-1.41,7.65-1.46,10.18-4,1.78-1.78,2.21-4.35,3.44-6.48,2-3.56,6.15-5.71,10.33-6.84,14-3.8,45.92,4.32,54.24,16.09a21.85,21.85,0,0,1,3.82,12.08A22.58,22.58,0,0,0,634,645.59c-8.32-11.77-40.21-19.89-54.24-16.09-4.18,1.13-8.3,3.28-10.33,6.84-1.23,2.13-1.66,4.7-3.44,6.48-2.53,2.53-6.78,2.58-10.18,4-2.59,1.07-4.51,4.23-3.81,6.35A6.88,6.88,0,0,1,555.8,648.81Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M547,663.34c.42-3.2,3-6.4,6.49-6.58a2.66,2.66,0,0,1-1.53-1.71,7.77,7.77,0,0,0-5,6.29,10.57,10.57,0,0,0,0,2.45C547,663.64,547,663.49,547,663.34Z"
            transform="translate(-57.92 -70.71)"/>
    </g>
    <rect x="609.91" y="230.24" width="91" height="118.67" fill="#fff"/>
    <rect x="609.91" y="230.24" width="91" height="118.67" opacity="0.1"/>
    <rect x="713.34" y="314.71" width="91" height="118.67" transform="translate(-110.37 63.4) rotate(-9.76)"
          fill="#fff"/>
    <rect x="727.88" y="400.99" width="21.38" height="27.88" transform="translate(-117.59 60.56) rotate(-9.76)"
          fill="#69d49f"/>
    <rect x="757.04" y="395.97" width="21.38" height="27.88" transform="translate(-116.32 65.43) rotate(-9.76)"
          fill="#69d49f"/>
    <rect x="721.04" y="359" width="74.64" height="27.88" transform="matrix(0.99, -0.17, 0.17, 0.99, -110.19, 63.31)"
          fill="#69d49f"/>
    <rect x="713.09" y="326.36" width="74.64" height="6.22" transform="matrix(0.99, -0.17, 0.17, 0.99, -102.93, 61.33)"
          fill="#69d49f"/>
    <rect x="715.5" y="337.64" width="74.64" height="6.22" transform="matrix(0.99, -0.17, 0.17, 0.99, -104.81, 61.9)"
          fill="#69d49f"/>
    <path d="M719,268.71s-41.83,42-20.83,52.67,34.67-35.5,34.67-35.5l3.32-10.5Z" transform="translate(-57.92 -70.71)"
          fill="#ae6e79"/>
    <path d="M715.34,265l-5.17,11.42S735.67,280,741,287.71l-3.16-20.15-3.75-8.6Z" transform="translate(-57.92 -70.71)"
          opacity="0.1"/>
    <path d="M716.34,264l-5.17,11.42S736.67,279,742,286.71l-3.16-20.15-3.75-8.6Z" transform="translate(-57.92 -70.71)"
          fill="#4c4981"/>
    <polygon points="812.78 403.26 812.23 403.64 812.99 403.98 813.81 403.91 813.91 403.34 812.78 403.26"
             fill="#464a5f"/>
    <polygon points="812.78 403.26 812.23 403.64 812.99 403.98 813.81 403.91 813.91 403.34 812.78 403.26"
             opacity="0.1"/>
    <polygon points="812.78 403.26 812.22 403.62 811.78 403.08 812.05 402.64 812.3 402.56 812.78 403.26"
             fill="#9c9ca1"/>
    <polygon points="812.78 403.26 812.22 403.62 811.78 403.08 812.05 402.64 812.3 402.56 812.78 403.26" opacity="0.1"/>
    <path d="M969.13,404.86l-40.94-59.13a4.87,4.87,0,0,0-6.78-1.25l-91.26,62.64a4.89,4.89,0,0,0-1.27,6.79l40.83,59.88Z"
          transform="translate(-57.92 -70.71)" fill="#9c9ca1"/>
    <path d="M969.13,404.86l-40.94-59.13a4.87,4.87,0,0,0-6.78-1.25l-91.26,62.64a4.89,4.89,0,0,0-1.27,6.79l40.83,59.88Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M970.13,405l-40.94-59.13a4.88,4.88,0,0,0-6.79-1.25L831.15,407.3a4.9,4.9,0,0,0-1.28,6.79L870.7,474Z"
          transform="translate(-57.92 -70.71)" fill="#9c9ca1"/>
    <path d="M1004.35,416.69l-34-10.92L870.91,474.7,905.45,486a5.23,5.23,0,0,0,4.63-.7l94.55-66.76A1,1,0,0,0,1004.35,416.69Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M1004.35,416.69l-34-10.92L870.91,474.7,905.45,486a5.23,5.23,0,0,0,4.63-.7l94.55-66.76A1,1,0,0,0,1004.35,416.69Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M1004.14,416l-34-10.93L870.7,474l34.54,11.28a5.19,5.19,0,0,0,4.63-.69l94.55-66.76A1,1,0,0,0,1004.14,416Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <g opacity="0.1">
      <path d="M954.07,402.36l-2.51-3.6a1,1,0,0,0-1.36-.25L946.47,401a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.93-2.47A1,1,0,0,0,954.07,402.36Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M947.37,407l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.72,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,947.37,407Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M940.33,411.87l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.73,2.51a1,1,0,0,0-.27,1.36l2.31,3.55a1,1,0,0,0,1.35.29l3.93-2.47A1,1,0,0,0,940.33,411.87Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M933.2,416.81l-2.52-3.6a1,1,0,0,0-1.35-.25l-3.73,2.52a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.48A1,1,0,0,0,933.2,416.81Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M926.08,421.74l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.72,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,926.08,421.74Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M919.27,427.12l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,919.27,427.12Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M912.15,432.05,909,427.59a.46.46,0,0,0-.64-.12l-4.58,3.09a.46.46,0,0,0-.12.63l2.87,4.41a.44.44,0,0,0,.63.14l4.83-3A.45.45,0,0,0,912.15,432.05Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M905.84,436.42,902.71,432a.46.46,0,0,0-.63-.11l-4.58,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,905.84,436.42Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M898.78,441.3l-3.13-4.46a.46.46,0,0,0-.63-.11l-4.58,3.08a.46.46,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.47.47,0,0,0,898.78,441.3Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M891.94,446l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,891.94,446Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M885.1,450.78,882,446.31a.46.46,0,0,0-.63-.11l-4.58,3.08a.46.46,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,885.1,450.78Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M878.26,455.51l-3.13-4.46a.45.45,0,0,0-.63-.12L869.92,454a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,878.26,455.51Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M949.57,395.85l-2.52-3.6a1,1,0,0,0-1.36-.25L942,394.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.94-2.47A1,1,0,0,0,949.57,395.85Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M939.72,381.62,937.2,378a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,939.72,381.62Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M942.86,400.49l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.3,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,942.86,400.49Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M935.82,405.36l-2.52-3.59a1,1,0,0,0-1.36-.25L928.22,404a1,1,0,0,0-.28,1.36l2.31,3.55a1,1,0,0,0,1.35.29l3.94-2.47A1,1,0,0,0,935.82,405.36Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M928.69,410.3l-2.51-3.6a1,1,0,0,0-1.36-.25L921.09,409a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.93-2.47A1,1,0,0,0,928.69,410.3Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M933.17,386.49l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,933.17,386.49Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M926.13,391.37l-2.52-3.6a1,1,0,0,0-1.36-.25L918.53,390a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.94-2.47A1,1,0,0,0,926.13,391.37Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M919,396.3l-2.52-3.59a1,1,0,0,0-1.35-.26L911.4,395a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.93-2.48A1,1,0,0,0,919,396.3Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M921.57,415.23l-2.52-3.59a1,1,0,0,0-1.35-.26L914,413.9a1,1,0,0,0-.27,1.35L916,418.8a1,1,0,0,0,1.34.3l3.94-2.48A1,1,0,0,0,921.57,415.23Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M914.45,420.16l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.72,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,914.45,420.16Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M907.64,425.54l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.45.45,0,0,0,.63.14l4.84-3A.46.46,0,0,0,907.64,425.54Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M901.33,429.91l-3.13-4.47a.46.46,0,0,0-.63-.11L893,428.41a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.44.44,0,0,0,901.33,429.91Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M894.27,434.79l-3.13-4.46a.46.46,0,0,0-.63-.11l-4.58,3.08a.45.45,0,0,0-.13.63l2.87,4.42a.47.47,0,0,0,.63.14l4.84-3A.47.47,0,0,0,894.27,434.79Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M887.43,439.53l-3.13-4.46a.45.45,0,0,0-.63-.12L879.09,438a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,887.43,439.53Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M931.13,398.59,928.61,395a1,1,0,0,0-1.35-.25l-3.73,2.52a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.29l3.94-2.47A1,1,0,0,0,931.13,398.59Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M924,403.53l-2.52-3.6a1,1,0,0,0-1.36-.25l-3.72,2.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,924,403.53Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M916.89,408.46l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.93-2.48A1,1,0,0,0,916.89,408.46Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M909.76,413.39l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35L904.2,417a1,1,0,0,0,1.34.3l3.94-2.47A1,1,0,0,0,909.76,413.39Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M903,418.77l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.46.46,0,0,0-.13.63l2.87,4.42a.45.45,0,0,0,.63.13l4.84-3A.46.46,0,0,0,903,418.77Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M896.65,423.14l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.57,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,896.65,423.14Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M889.59,428l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.58,3.08a.48.48,0,0,0-.13.64l2.88,4.41a.46.46,0,0,0,.63.14l4.83-3A.45.45,0,0,0,889.59,428Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M882.75,432.76l-3.13-4.46a.46.46,0,0,0-.64-.12l-4.58,3.09a.46.46,0,0,0-.12.63l2.87,4.41a.44.44,0,0,0,.63.14l4.83-3A.45.45,0,0,0,882.75,432.76Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M880.59,444.26l-3.13-4.46a.46.46,0,0,0-.63-.11l-4.58,3.08a.45.45,0,0,0-.13.63l2.87,4.42a.47.47,0,0,0,.63.14l4.84-3A.47.47,0,0,0,880.59,444.26Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M873.75,449l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,873.75,449Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M884.49,420.66l-3-4.2a.62.62,0,0,0-.85-.15l-4.32,2.91a.61.61,0,0,0-.17.85l2.7,4.15a.6.6,0,0,0,.84.19l4.57-2.87A.62.62,0,0,0,884.49,420.66Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M877.65,425.4l-2.95-4.2a.61.61,0,0,0-.85-.16L869.53,424a.6.6,0,0,0-.17.84l2.7,4.16a.62.62,0,0,0,.84.19l4.57-2.87A.62.62,0,0,0,877.65,425.4Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M870.81,430.14l-2.95-4.21a.62.62,0,0,0-.85-.15l-4.32,2.91a.61.61,0,0,0-.17.85l2.7,4.15a.6.6,0,0,0,.84.19l4.57-2.87A.61.61,0,0,0,870.81,430.14Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M864.06,435l-3.13-4.47a.46.46,0,0,0-.63-.11l-4.58,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.44.44,0,0,0,864.06,435Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M945,388.85,942.29,385a1,1,0,0,0-1.36-.24l-10.58,7.41a1,1,0,0,0-.26,1.34l2.55,3.94a1,1,0,0,0,1.39.27l10.74-7.51A1,1,0,0,0,945,388.85Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M875.89,437.35l-3.32-4.69a.45.45,0,0,0-.64-.11l-11.42,8a.45.45,0,0,0-.12.62l3.12,4.83a.46.46,0,0,0,.65.13L875.77,438A.47.47,0,0,0,875.89,437.35Z"
            transform="translate(-57.92 -70.71)"/>
      <path d="M912.22,401.48,909.16,397a.46.46,0,0,0-.63-.12L883.17,414a.46.46,0,0,0-.13.63l3.13,4.8a.46.46,0,0,0,.65.13l25.28-17.44A.46.46,0,0,0,912.22,401.48Z"
            transform="translate(-57.92 -70.71)"/>
    </g>
    <path d="M954.74,402.48l-2.52-3.6a1,1,0,0,0-1.36-.25l-3.72,2.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,954.74,402.48Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M948,407.12l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,948,407.12Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M941,412l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.72,2.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.94-2.47A1,1,0,0,0,941,412Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M933.86,416.93l-2.52-3.6a1,1,0,0,0-1.35-.25l-3.73,2.52A1,1,0,0,0,926,417l2.31,3.55a1,1,0,0,0,1.35.3l3.93-2.48A1,1,0,0,0,933.86,416.93Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M926.74,421.86l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.3,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,926.74,421.86Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M919.93,427.24l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,919.93,427.24Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M912.81,432.17l-3.13-4.46a.46.46,0,0,0-.64-.12l-4.57,3.09a.45.45,0,0,0-.13.63l2.87,4.42a.45.45,0,0,0,.63.13l4.84-3A.47.47,0,0,0,912.81,432.17Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M906.5,436.54l-3.13-4.47a.46.46,0,0,0-.63-.11L898.16,435a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,906.5,436.54Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M899.44,441.43,896.31,437a.46.46,0,0,0-.63-.11l-4.58,3.08a.46.46,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,899.44,441.43Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M892.6,446.16l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.45.45,0,0,0,.63.14l4.84-3A.46.46,0,0,0,892.6,446.16Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M885.76,450.9l-3.13-4.47a.46.46,0,0,0-.63-.11l-4.58,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,885.76,450.9Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M878.92,455.63l-3.13-4.46a.45.45,0,0,0-.63-.12l-4.58,3.09a.45.45,0,0,0-.13.63l2.87,4.41a.45.45,0,0,0,.63.14l4.84-3A.46.46,0,0,0,878.92,455.63Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M950.23,396l-2.52-3.6a1,1,0,0,0-1.35-.25l-3.73,2.52a1,1,0,0,0-.27,1.35l2.3,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,950.23,396Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M940.38,381.74l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.47A1,1,0,0,0,940.38,381.74Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M943.52,400.61,941,397a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.47A1,1,0,0,0,943.52,400.61Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M936.48,405.48,934,401.89a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.36l2.3,3.55a1,1,0,0,0,1.35.29l3.94-2.47A1,1,0,0,0,936.48,405.48Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M929.36,410.42l-2.52-3.6a1,1,0,0,0-1.36-.25l-3.72,2.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,929.36,410.42Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M933.83,386.61,931.31,383a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.47A1,1,0,0,0,933.83,386.61Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M926.79,391.49l-2.52-3.6a1,1,0,0,0-1.35-.25l-3.73,2.52a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.48A1,1,0,0,0,926.79,391.49Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M919.67,396.42l-2.52-3.59a1,1,0,0,0-1.36-.25l-3.72,2.51a1,1,0,0,0-.28,1.35L914.1,400a1,1,0,0,0,1.35.3l3.93-2.48A1,1,0,0,0,919.67,396.42Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M922.23,415.35l-2.52-3.59a1,1,0,0,0-1.35-.25L914.63,414a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.48A1,1,0,0,0,922.23,415.35Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M915.11,420.28l-2.52-3.59a1,1,0,0,0-1.35-.25L907.51,419a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,915.11,420.28Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M908.3,425.66l-3.13-4.46a.45.45,0,0,0-.63-.12L900,424.17a.45.45,0,0,0-.13.63l2.87,4.41a.45.45,0,0,0,.63.14l4.84-3A.47.47,0,0,0,908.3,425.66Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M902,430l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.58,3.08a.48.48,0,0,0-.13.64l2.88,4.41a.46.46,0,0,0,.63.14l4.83-3A.45.45,0,0,0,902,430Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M894.93,434.92l-3.13-4.47a.46.46,0,0,0-.63-.11l-4.58,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.44.44,0,0,0,894.93,434.92Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M888.1,439.65,885,435.19a.45.45,0,0,0-.63-.12l-4.58,3.09a.46.46,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.45.45,0,0,0,888.1,439.65Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M931.79,398.72l-2.51-3.6a1,1,0,0,0-1.36-.25l-3.73,2.52a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.35.29l3.93-2.47A1,1,0,0,0,931.79,398.72Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M924.67,403.65l-2.52-3.59a1,1,0,0,0-1.35-.26l-3.73,2.52a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.48A1,1,0,0,0,924.67,403.65Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M917.55,408.58,915,405a1,1,0,0,0-1.36-.25L910,407.25a1,1,0,0,0-.28,1.35l2.31,3.55a1,1,0,0,0,1.35.3l3.94-2.47A1,1,0,0,0,917.55,408.58Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M910.42,413.51l-2.52-3.59a1,1,0,0,0-1.35-.25l-3.73,2.51a1,1,0,0,0-.27,1.35l2.31,3.55a1,1,0,0,0,1.34.3l3.94-2.47A1,1,0,0,0,910.42,413.51Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M903.62,418.89l-3.13-4.46a.46.46,0,0,0-.64-.12l-4.58,3.09a.46.46,0,0,0-.13.63l2.87,4.42a.46.46,0,0,0,.64.13l4.83-3A.46.46,0,0,0,903.62,418.89Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M897.31,423.26l-3.13-4.46a.45.45,0,0,0-.63-.12L889,421.76a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.46.46,0,0,0,897.31,423.26Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M890.25,428.15l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.57,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,890.25,428.15Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M883.41,432.88l-3.13-4.46a.46.46,0,0,0-.64-.12l-4.57,3.09a.45.45,0,0,0-.13.63l2.87,4.42a.45.45,0,0,0,.63.13l4.84-3A.47.47,0,0,0,883.41,432.88Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M881.26,444.39l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.58,3.08a.47.47,0,0,0-.13.64l2.87,4.41a.47.47,0,0,0,.63.14l4.84-3A.45.45,0,0,0,881.26,444.39Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M874.42,449.12l-3.13-4.46a.46.46,0,0,0-.64-.12l-4.58,3.09a.46.46,0,0,0-.13.63l2.87,4.41a.46.46,0,0,0,.64.14l4.83-3A.45.45,0,0,0,874.42,449.12Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M885.15,420.79l-2.95-4.21a.62.62,0,0,0-.85-.15L877,419.34a.62.62,0,0,0-.17.85l2.7,4.16a.62.62,0,0,0,.85.18l4.56-2.87A.61.61,0,0,0,885.15,420.79Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M878.31,425.52l-2.95-4.2a.61.61,0,0,0-.85-.16l-4.32,2.92a.61.61,0,0,0-.17.84l2.7,4.16a.63.63,0,0,0,.85.19l4.57-2.87A.63.63,0,0,0,878.31,425.52Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M871.47,430.26l-3-4.21a.61.61,0,0,0-.84-.15l-4.33,2.91a.62.62,0,0,0-.17.85l2.7,4.16a.62.62,0,0,0,.85.18l4.57-2.87A.61.61,0,0,0,871.47,430.26Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M864.73,435.13l-3.13-4.47a.47.47,0,0,0-.64-.11l-4.58,3.08a.47.47,0,0,0-.12.64l2.87,4.41a.46.46,0,0,0,.63.14l4.84-3A.46.46,0,0,0,864.73,435.13Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M945.67,389,943,385.14a1,1,0,0,0-1.36-.24L931,392.31a1,1,0,0,0-.26,1.34l2.56,3.94a1,1,0,0,0,1.39.27l10.73-7.51A1,1,0,0,0,945.67,389Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M876.55,437.47l-3.32-4.69a.45.45,0,0,0-.64-.11l-11.42,8a.46.46,0,0,0-.12.63l3.13,4.82a.46.46,0,0,0,.65.13l11.61-8.13A.47.47,0,0,0,876.55,437.47Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M912.88,401.6l-3-4.45a.47.47,0,0,0-.64-.13l-25.35,17.1a.45.45,0,0,0-.13.63l3.12,4.8a.46.46,0,0,0,.65.13l25.28-17.44A.46.46,0,0,0,912.88,401.6Z"
          transform="translate(-57.92 -70.71)" fill="#464a5f"/>
    <path d="M913,386.32l-13.48-19.59a2.5,2.5,0,0,0-3.46-.66L858.93,391a2.51,2.51,0,0,0-.67,3.49L872,414.64a2.5,2.5,0,0,0,3.49.66l36.87-25.51A2.49,2.49,0,0,0,913,386.32Z"
          transform="translate(-57.92 -70.71)" opacity="0.1"/>
    <path d="M913.62,386.44l-13.48-19.59a2.51,2.51,0,0,0-3.46-.66L859.6,391.14a2.5,2.5,0,0,0-.68,3.49l13.7,20.13a2.5,2.5,0,0,0,3.49.66L913,389.91A2.49,2.49,0,0,0,913.62,386.44Z"
          transform="translate(-57.92 -70.71)" fill="#939498"/>
    <path d="M997.21,418.1l-28.6-9.54-91.73,63.72,28.39,9.25a5.2,5.2,0,0,0,4.59-.68l87.61-60.92A1,1,0,0,0,997.21,418.1Z"
          transform="translate(-57.92 -70.71)" fill="#69d49f"/>
  </svg>
export default InterviewImage